let constants = {
  SEX_LIST: [
    { value: 0, label: "女" },
    { value: 1, label: "男" }
  ],

  // 告知书类型
  INSULATE_TYPE: [
    { value: 1, label: "开始隔离" },
    { value: 2, label: "解除隔离" }
  ],

  // 转出类型
  TRANSFER_TYPE_SELECT: [
    { value: "1", label: "解除管控" },
    { value: "2", label: "送医治疗" },
    { value: "3", label: "送往隔离点" },
    { value: "4", label: "居家隔离" },
    { value: "5", label: "居家监测" },
    { value: "9", label: "其他" }
  ],

  // 身份证类型
  TYPE_CERTIFICATE: [
    { value: "", label: "全部" },
    { value: "1", label: "居民身份证" },
    { value: "2", label: "护照" },
    { value: "3", label: "港澳通行证" },
    { value: "4", label: "台胞证" },
    { value: "5", label: "其他" }
  ],

  // 核查状态
  CHECK_STATUS_SELECT: [
    { value: "", label: "全部" },
    { value: "0", label: "已解除" },
    { value: "1", label: "隔离中" }
  ],

  // 核查状态
  CHECK_STATUS_SELECT2: [
    { value: "", label: "全部" },
    { value: "1", label: "已核查" },
    { value: "0", label: "未核查" }
  ],

  // 核酸检测状态
  CHECK_STATUS: [
    { value: "1", label: "已采样" },
    { value: "2", label: "已报告" }
  ],

  // 采集状态
  GATHER_STATUS: [
    { value: "", label: "全部" },
    { value: "1", label: "已采集" },
    { value: "2", label: "已报告" },
    { value: "0", label: "未采集" }
  ],

  // 存档状态
  ARCHIVE_STATUS: [
    { value: "1", label: "已存档" },
    { value: "0", label: "未存档" }
  ],

  // 人群分类
  TYPEPOP_SELECT: [
    { value: "1", label: "南京禄口集中隔离" },
    { value: "2", label: "境外来区人员隔离" },
    { value: "3", label: "市外入境来区集中隔离" },
    { value: "4", label: "中高风险地区临时隔离" },
    { value: "5", label: "密切接触医学观察" },
    { value: "6", label: "次密切接触医学观察" },
    { value: "7", label: "参照密切接触医学观察" },
    { value: "8", label: "出院病人医学观察" }
  ],

  // 风险等级
  DANGER_TYPE: [
    { value: "3", label: "高风险" },
    { value: "2", label: "中风险" },
    { value: "1", label: "低风险" }
  ],

  // 交通工具类型 （使用接口）
  VEHICLE_TYPE: [
    { value: "1", label: "汽车" },
    { value: "2", label: "火车" },
    { value: "3", label: "飞机" },
    { value: "4", label: "轮船" },
    { value: "5", label: "自驾" },
    { value: "6", label: "公共汽车" },
    { value: "7", label: "其他" },
  ],

  // 国籍选项
  NATIONALITY_SELECT: [
    { value: "1", label: "中国大陆" },
    { value: "2", label: "中国香港" },
    { value: "3", label: "中国澳门" },
    { value: "4", label: "中国台湾" }
  ],

  // 房间数
  FLOORS: [
    { num: "11-1", state: "可用" },
    { num: "11-2", state: "可用" },
    { num: "11-3", state: "可用" },
    { num: "11-4", state: "使用中" }
  ],

  // 用户角色
  USER_ROLE: [
    { value: "1", label: "管理员" },
    { value: "2", label: "医生" },
    { value: "3", label: "护士" },
    { value: "4", label: "驻点监督员" }
  ],

  // 隔离时间类别
  QUARANTINE_TIME_TYPE: [
    { value: "1", label: "抵渝时间" },
    { value: "2", label: "密接时间" },
    { value: "3", label: "自定义时间" }
  ],

  // 检测规则
  DETECTION_TYPE: [
    { value: "0", label: "默认规则" },
    { value: "1", label: "自定义规则" }
  ],

  // 用户状态
  USER_STATUS: [
    { value: "1", label: "启用" },
    { value: "2", label: "停用" }
  ],

  // 状态
  USER_STATUS2: [
    { value: "1", label: "启动" },
    { value: "0", label: "停用" }
  ],

  // 申请解除状态
  APPLY_RELIEVE_STATUS: [
    { value: "0", label: "待申请" },
    { value: "1", label: "审核中" },
    { value: "2", label: "已通过" },
    { value: "3", label: "已拒绝" }
  ]
};
export default constants;
//流调结果
export const SURVEY_RESULT = [
  { value: '0', label: '排除' },
  { value: '1', label: '他区协查' },
  { value: '2', label: '居家隔离' },
  { value: '3', label: '集中隔离' },
  { value: '5', label: '院内隔离' },
  { value: '4', label: '其他' },
]
//病例分类
export const CASE_TYPE = [
  { value: '1', label: '确诊病例' },
  { value: '2', label: '无症状感染者' },
  { value: '3', label: '疑似病例' },
  { value: '4', label: '其他' }
]
export const RELATION_TYPE = [
  { value: '0', label: '亲属' },
  { value: '1', label: '同事' },
  { value: '2', label: '朋友' },
  { value: '3', label: '同学' },
  { value: '4', label: '其他' }
]
export const CONTACT_FREQUENCY_TYPE = [
  { value: '0', label: '经常（>=4次/周）' },
  { value: '1', label: '一般（2-3次/周）' },
  { value: '2', label: '偶尔（1次/周）' },
  { value: '3', label: '不详' }
]
export const CONTACT_ADDRESS = [
  { value: '0', label: '家中' },
  { value: '1', label: '医疗机构' },
  { value: '2', label: '工作场所' },
  { value: '3', label: '娱乐场所' },
  { value: '4', label: '不详' },
  { value: '5', label: '其他' }
]
//接触方式
export const CONTACT_TYPE = [
  { value: '0', label: '同餐' },
  { value: '1', label: '同住' },
  { value: '2', label: '同屋' },
  { value: '3', label: '同交通工具' },
  { value: '4', label: '同室工作或学习' },
  { value: '5', label: '诊疗、护理' },
  { value: '6', label: '同病房' },
  { value: '7', label: '娱乐活动' },
  { value: '8', label: '其他' }
]
export const THREE_SELECT = [
  { value: '0', label: '否' },
  { value: '1', label: '是' },
  { value: '2', label: '不详' }
]
export const PERSON_STATUS = [
  { value: '0', label: '待转运' },
  { value: '1', label: '转运中' },
  { value: '2', label: '取消转运' },
  { value: '3', label: '待隔离' },
  { value: '4', label: '隔离中' },
  { value: '5', label: '待解除隔离' },
  { value: '6', label: '已解除隔离' }
]
//前置任务管理任务状态
export const PRETASK_STATUS =[
  { value: "", label: "全部" },
  { value: '0', label: '未处理' },
  { value: '1', label: '执行中' },
  { value: '2', label: '取消' },
  { value: '3', label: '完成' }
]
//前置任务信息类别
export const INFO_TYPE =[
  { value: '1', label: '协查函' },
  { value: '2', label: '报告' },
  { value: '3', label: '其他' },
]
//前置任务流调状态
export const SURVEY_STATUS =[
  {value: '0', label: '待流调'},
  {value: '1', label: '流调中'},
  {value: '2', label: '流调完成'},
]



